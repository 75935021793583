.alignLeft{
    text-align: left;
}

.alignCentre{
    text-align: center;
}


.primary{
    text-align: left;
    color:#3E363F;
    font-family:'Khula';
}

.secondary{
    text-align: left;
    color:#6C6A71;
    font-family:'Khula';
}

.disabled{
    text-align: left;
    color:#AAAAAA;
    font-family:'Khula';
}

.hotOrange{
    text-align: left;
    color:#D73907;
    font-size: 100px;
    font-family:'Khula';
}

.white{
    text-align: left;
    color:white;
    font-family:'Khula';
}


.indigo{
    text-align: left;
    color:#340482;
    font-family:'Khula';
}


.error{
    text-align: left;
    color:#E16794;
    font-family:'Khula';
}

.alignRight{
    text-align: right;
}


/* ----------------------------------------------------- */



.huge{
    font-weight: 600;
    font-size:58px;
    line-height: 108%;
}

.title1{
    font-weight:700;
    font-size:42px;
    line-height: 112%;
}

.title2{
    font-weight:700;
    font-size:34px;
    line-height: 128%;
}

.title3{
    font-weight:700;
    font-size:28px;
    line-height: 124%;
}

.subtitle1{
    font-weight:700;
    font-size:22px;
    line-height: 132%;
}

.subtitle2{
    font-weight:600;
    font-size:18px;
    line-height: 140%;
}

.subtitle2bold{
    font-weight:700;
    font-size:18px;
    line-height: 132%; 
}

.body1{
    font-weight:400;
    font-size:16px;
    line-height: 140%;
}

.body2{
    font-weight:400;
    font-size:14px;
    line-height: 132%;
}

.caption1bold{
    font-weight:600;
    font-size:14px;
    line-height: normal;
}

.caption{
    font-weight:600;
    font-size:11px;
    line-height: 140%;
}

.buttonSmall{
    font-weight:600;
    font-size:15px;
    line-height: normal;
}

.buttonLarge{
    font-weight:600;
    font-size:18px;
    line-height: 140%;
}

.input{
    font-weight:400;
    font-size:15px;
    line-height: normal;
}
.body1bold{
    font-weight:500;
    font-size:17px;
    line-height: 130%;
}
.testimony{
    font-weight:500;
    font-size:16px;
    line-height: 130%;
}

.other{
    font-weight:500;
    font-size:15px;
}

.other2{
    font-weight:400;
    font-size:15px;
}

.other3{
    font-weight:600;
    font-size:16px;
}


.underline{
    text-decoration: underline;
}


.maxLines5{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5; 
    -webkit-box-orient: vertical;
  }
.oneLine{
    white-space: nowrap;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
    max-width: 150px;
}

@media (max-width:600px) {


    .huge{
        font-size: 42px;
        font-style: normal;
        font-weight: 700;
        line-height: 112%;
    }

    .title2{
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
    }

    .title3{
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 132%;
    }

    .subtitle1{
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 132%;
    }

    .buttonLarge{
        font-weight:600;
        font-size:15px;
        line-height: normal;
    }
}