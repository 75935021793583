
.replyToolBubble{
    width: 80%;
    height: auto;
    background-color: #F5F5F7;
    border-radius: 20px;
    padding: 24px 32px 40px 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 56px;
}


.replyChatBubble{
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    color: black;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    background-color: #F1EDFF;
    border-radius: 20px;
    border: 2px #340482 solid;
}

.replyChatBubble.you{
    background-color: white;
    color: #340482;
}