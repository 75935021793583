:root {
    --WHITE:#FFFFFF;
    --GHOST_WHITE: #F4F4F8;
    --GRADIENT: linear-gradient(168.03deg, #E06C61 0.64%, #E4765E 91.25%);    ;

    --TERRA_COTTA: #E06C61;
    --MUNSELL: #0193A1;

    --BLACK_COFFEE: #3E363F;
    --DIM_GREY: #514653;
    --DISABLED: #909090;


    --INDIGO-BLUE:#340482;
    --INDIGO-BLUE2:#B6AAF6;
    --INDIGO-BLUE3:#F1EDFF;

    --HOT-ORANGE:#D73907;
    --HOT-ORANGE2:#992905;
    --HOT-ORANGE3:#B83106;

    --BLACK-COFFEE:#3E363F;
    --BLACK-COFFEE2:#120F12;
    --BLACK-COFFEE3:#262126;

    --SEASALT-GREY:#F5F5F7;
  }