@import '../color.css';

.row{
    display: flex;
    box-sizing: border-box;
 
}

.column{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.grid{
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: auto auto auto;
    row-gap: 20px;
    column-gap: 20px;
}

.jc-self-end{
    justify-self: flex-end;
}

.jc-centre{
    justify-content: center;
}

.jc-start{
    justify-content: flex-start;
}

.jc-end{
    justify-content: flex-end;
}

.jc-between{
    justify-content: space-between;
}

.ai-centre{
    align-items: center;
}

.ai-start{
    align-items: flex-start;
}

.ai-end{
    align-items: flex-end;
}

.ai-between{
    align-items: space-between;
}

.flex100{
    width: 100%;
}

.height100{
    height: 100%;
}

.height100px{
    height: 100px;
}

.minHeight20px{
    min-height: 20px;
}

.maxHeight700px{
    max-height: 700px;
}


.width100{
    width: 100%;
}


.width80{
    width: 80%;
}

.width75{
    width: 75%;
}

.width50{
    width: 50%;
}

.width40{
    width: 40%;
}


.maxWidth350{
    max-width: 350px;
}

.maxWidth400{
    max-width: 400px;
}

.maxWidth640{
    max-width: 640px;
}

.maxWidth800{
    max-width: 800px;
}

.maxWidth1366{
    max-width: 1366px;
}

.mobileW100{}

.gap4{
    gap: 4px;
}

.gap8{
    gap: 8px;
}

.gap12{
    gap: 12px;
}


.gap18{
    gap: 18px;
}


.gap20{
    gap: 20px;
}


.gap24{
    gap: 24px;
}


.gap32{
    gap: 32px;
}

.gap40{
    gap: 40px;
}

.gap64{
    gap: 64px;
}

.gap80{
    gap: 80px;
}

.gap100{
    gap: 100px;
}



.paddingLeft4{
    padding-left: 4px;
}
.paddingSides16{
    padding-left: 16px;
    padding-right: 16px;
}


.paddingTops16{
    padding-top: 16px;
    padding-bottom: 16px;
}

.padding20{
    padding: 20px;
}

.padding80{
    padding: 80px;
}

.padding100{
    padding: 100px;
}


.paddingTop80{
    padding-top: 80px;
}


.topBorder{
    border-top: 1px solid var(--gray-200);
}


.margin20{
    margin: 20px;
}


.marginTop8{
    margin-top: 8px;
}


.marginTop20{
    margin-top: 20px;
}
.marginTop40{
    margin-top: 40px;
}

.marginBottom40{
    margin-bottom: 40px;
}
.marginTop-50{
    margin-top: -50px;
}


.marginBottom50{
    margin-bottom: 50px;
}

.tile{
    padding: 24px;
    border-radius: 20px;
    background: #FFF;
    box-shadow: 1px 1px 12px 0px rgba(133, 125, 122, 0.25);
}

.borderBox{
    box-sizing: border-box;
}

.bottomRight{
    width: 83px;
    height: 83px;
    position: absolute;
    bottom: -41.5px;
    right: -41.5px;
}

.bottomLeft{
    width: 83px;
    height: 83px;
    position: absolute;
    bottom: -41.5px;
    left: -41.5px;
}

.lightBkg{
    background-color: #0001;
}

.red{
    color: red;
}

.redBkg{
    background-color: red;
}

.pointer{
    cursor: pointer;
}

.hoverUnderline:hover{
    text-decoration: underline;
}

.relative{
    position: relative;
}

.noXOverFlow{
    overflow-x: hidden;
}

.noYOverFlow{
    overflow-y: hidden;
}

.scrollY{
    overflow-y: scroll;
    overflow: -moz-scrollbars-vertical; 
}

.strikethrough{
    text-decoration: line-through;
}

.italics{
    font-style: italic;
}

.z10{
    z-index: 10;
}

.imgCover{
    object-position: .245px;
}

/* .row.mobile-column{

} */

.reveal {
    position: relative;
    opacity: 0;
  }
  
  .reveal.active {
    opacity: 1;
  }
  .active.fade-bottom {
    animation: fade-bottom 1s ease-in;
  }


  @keyframes fade-bottom {
    0% {
      transform: translateY(50px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }


.textInputSales{
    width: 100%;
    height: auto;
    padding: 12px;
    border-radius: 12px;
}

.textAreaSales{
    width: 700px;
    height: 500px;
    resize: none;
    padding: 12px;
}


@media (max-width:1024px) {
    
    .row.mobile-column{
        flex-direction: column;
    }

    .row.mobile-column-reverse{
        flex-direction: column-reverse;
    }

    .row.mobile-jc-centre{
        justify-content: center;
    }

    .bottomLeft{
        left: -17.5px;
    }

    .bottomRight{
        right: -17.5px;
        
    }


    .mobile-ai-start{
        align-items: start;
    }

    .mobile-ai-end{
        align-items: flex-end;
    }

    .step2-home{
        align-self: flex-end;
        width: fit-content;
        align-items: flex-start;
    }

    .mobileW100{
        width: 100%;
    } 
    

    

}

@media (max-width:1400px) {

    .reveal {
        position: relative;
        opacity: 1;
      }
}

@media (max-width:600px) {

    .mobile-gone{
        display: none;
    }
}


.greyBkg{
    background-color:var(--GHOST_WHITE);
}