@import url("https://fonts.googleapis.com/css2?family=Khula:wght@300;400;600;700;800&display=swap");
    


    .divVox:empty{
      display:block;
    }

    .voxMail.row{
      display: flex;
    }
    
    .voxMail.column{
        display: flex;
        flex-direction: column;
        height:auto;
    }
    
    .voxMail.jc-centre{
        justify-content: center;
    }
    
    .voxMail.jc-start{
        justify-content: flex-start;
    }
    
    .voxMail.jc-end{
        justify-content: flex-end;
    }
    
    .voxMail.jc-between{
        justify-content: space-between;
    }
    
    .voxMail.ai-centre{
        align-items: center;
    }
    
    .voxMail.ai-start{
        align-items: flex-start;
    }
    
    .voxMail.ai-end{
        align-items: flex-end;
    }
    
    .voxMail.ai-between{
        align-items: space-between;
    }
    
    .voxMail.ai-stretch{
        align-items: stretch;
    }

    .voxMail.height100{
      height: 100%;
    }

    .voxMail.width100{
      width: 100%;
    }

    .voxMail.gap4{
      gap: 4px;
    }
    .voxMail.gap8{
      gap: 8px;
    }

    .voxMail.gap11{
      gap: 11px;
    }
    .voxMail.gap12{
      gap: 12px;
    }
    
    .voxMail.gap16{
      gap: 16px;
    }

    .voxMail.gap20{
      gap: 20px;
    }

    .voxMail.gap24{
      gap: 24px;
    }

    .voxMail.gap32{
      gap: 32px;
    }

    .voxMail.gap38{
      gap: 38px;
    }

    .voxMail.gap40{
      gap: 40px;
    }

    
    .voxMail.gap48{
      gap: 48px;
    }

    .voxMail.gap56{
      gap: 56px;
    }

    .voxMail.gap64{
      gap: 64px;
    }

    .voxMail.gap69{
      gap: 69px;
    }

    .voxMail.gap74{
      gap: 74px;
    }
    
    .voxMail.gap80{
      gap: 80px;
    }

    .voxMail.gap107{
      gap: 107px;
    }

    .voxMail.gap160{
      gap: 160px;
    }

    .voxMail.padding24{
      padding:24px;
    }

    .voxMailText.marginMinus3{
      margin-top:-3px
    }

    .voxMail.overflowY{
      overflow-y: auto;
    }

    .voxMail.borderBox{
      box-sizing: border-box;
    }

    .voxMail.red{
      background-color: red;
    }

    .voxMail.blue{
      background-color: blue;
    }

    .voxMail.pointer{
      cursor: pointer;
    }

    .voxMail.relative{
      position: relative;
    }

    .voxMailText{
      leading-trim: both;
      text-edge: cap;
      letter-spacing:normal;
      line-height:normal;
    }

    .voxMailText.primary{
      text-align: left;
      color:#3E363F;
      font-family:'Khula';
    }
    
    .voxMailText.secondary{
        text-align: left;
        color:#6C6A71;
        font-family:'Khula';
    }
    
    .voxMailText.disabled{
        text-align: left;
        color:#AAAAAA;
        font-family:'Khula';
    }
    
    .voxMailText.hotOrange{
        text-align: left;
        color:#D73907;
        font-size: 100px;
        font-family:'Khula';
    }
    
    .voxMailText.white{
        text-align: left;
        color:white;
        font-family:'Khula';
    }
    
    .voxMailText.indigo{
        text-align: left;
        color:#340482;
        font-family:'Khula';
    }
    
    .voxMailText.error{
        text-align: left;
        color:#E16794;
        font-family:'Khula';
    }

    .voxMailText.huge{
      font-weight: 600;
      font-size:58px;
      line-height: 108%;
    }
    
    .voxMailText.title1{
        font-weight:700;
        font-size:42px;
        line-height: 112%;
    }
    
    .voxMailText.title2{
        font-weight:700;
        font-size:34px;
        line-height: 128%;
    }
    
    .voxMailText.title3{
        font-weight:700;
        font-size:28px;
        line-height: 124%;
        letter-spacing: 0.2px;
    }
    
    .voxMailText.subtitle1{
        font-weight:700;
        font-size:22px;
        line-height: 132%;
    }
    
    .voxMailText.subtitle2{
        font-weight:600;
        font-size:18px;
        line-height: 140%;
    }
    
    .voxMailText.subtitle2bold{
        font-weight:700;
        font-size:18px;
        line-height: 132%;
    }
    
    .voxMailText.vmbody1{
        font-weight:400;
        font-size:16px;
        line-height: 140%;
    }
    
    .voxMailText.vmbody2{
        font-weight:400;
        font-size:14px;
        line-height: 132%;
    }
    
    .voxMailText.caption1bold{
        font-weight:600;
        font-size:14px;
        line-height: normal;
    }
    
    .voxMailText.caption{
        font-weight:600;
        font-size:11px;
        line-height: 140%; 
    }

    .voxMailText.captionMini{
      font-size: 11px;
      font-weight: 600;
    }
    
    .voxMailText.buttonSmall{
        font-weight:600;
        font-size:15px;
        line-height: normal;
        font-family:'Khula';
    }
    
    .voxMailText.buttonLarge{
        font-weight:600;
        font-size:18px;
        line-height: normal;
    }
    
    .voxMailText.input{
        font-weight:400;
        font-size:15px;
        line-height: normal;
    }

    .voxMailText.body1bold{
        font-weight:500;
        font-size:17px;
    }

    .voxMailText.testimony{
        font-weight:500;
        font-size:16px;
    }
    
    .voxMailText.other{
        font-weight:500;
        font-size:15px;
    }
    
    .voxMailText.other2{
        font-weight:700;
        font-size:14px;
    }
    
    .voxMailText.other3{
        font-weight:600;
        font-size:16px;
    }

    .voxMail.bottomLeft{
      height: fit-content;
      width: fit-content;
      position: fixed;
      bottom: 20px;
      left: 30px;
      z-index: 10000;
      align-items:flex-start;
    }

    .voxMail.bottomRight{
      height: fit-content;
      width: fit-content;
      position: fixed;
      bottom: 20px;
      right: 30px;
      z-index: 10000;
    }

    .voxMail.mainDiv{
      font-family: 'Khula', sans-serif;
      display:block;
    }

    .voxMail.imageContainer{
      height: 80px;
      width: 80px;
      cursor: pointer;
      display:block;
    }


    .voxMail.imageContainer:hover{
      transform: scale(1.1);
    }
    

    voxMail.svgLogoClosed:hover{
      transform: scale(1.1);
    }

    .voxMail.svgLogoClosed:hover path{
       transform: scale(1.0);
    }
    .voxMail.svgLogoClosed:hover circle{
      transform: scale(1.0);
    }
    .voxMail.svgLogoClosed:hover rect{
      transform: scale(1.0);
    }

    .voxMail.closeWidgetButton{
      height:80px;
      width:80px;
      border-radius:50%;
      cursor:pointer;
    }

    .voxMail.widgetTile{
      position:relative;
      display: flex;
      /* width: 368px; */
      /* height: 544px; */
      flex-direction: column;
      align-items: flex-start;
      border-radius: 16px;
      /* background: #FFF; */
      /* box-shadow: 4px 4px 15px 0px rgba(0, 0, 0, 0.20); */
    }

    .voxMail.vmHeader{
      box-sizing: border-box;
      justify-self: flex-start;
      padding: 12px 24px;
      background: #F5F5F7;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;      
    }

    .voxMail.vmFooter{
      /* padding: 12px 0px; */
      background: #F5F5F7;
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
      min-height: 36px;      
    }

    .voxMail.poweredBy{
      height: 12px;
      cursor:pointer;
    }

    .voxMail.continueOuterCircle{
      width:83px;
      height:83px;
      box-sizing:border-box;
      border-radius:50%;
      border: #3E363F 1px solid;
      padding:9px;
      z-index:20;
    }

    .voxMail.continueInnerCircle{
      width: 64px;
      height: 64px;
      border-radius: 314.15px;
      cursor:pointer;
      background: var(--Highlight-Hot-orange, #D73907);
      box-shadow: 0px 1.571px 7.854px 0px rgba(0, 0, 0, 0.12), 0px 3.142px 3.142px 0px rgba(0, 0, 0, 0.14), 0px 4.712px 1.571px -3.142px rgba(0, 0, 0, 0.20);
    }

    .voxMail.continueInnerCircle:hover{
      background:#B83106;
    }
    .voxMail.continueInnerCircle:focus{
      background:#992905;
    }
    .voxMail.continueInnerCircle:active{
      background:#992905;
    }

    .voxMail.introImage{
      width: 182.937px;
      height: 121px;  
      position: absolute;
      left: 12px;
      bottom: 31px;
    }

    .voxMail.textInput{
      height: 40px;
      width:100%;
      padding: 5px 12px 0px 12px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      border-radius: 8px;
      border: 1px solid #3E363F;
      box-sizing: border-box;
      outline:none;
      background-color: white;
      margin-bottom:0px;
    }

    .voxMail.textInput.phone{
      padding: 5px 12px 0px 50px;
    }

    .voxMail.textInput::placeholder {
      text-align: left;
    }

    .voxMail.textArea::placeholder {
      text-align: left;
    }

    .voxMail.textInput.dropdown{
      padding: 7px 50px 0px 10px;
    }

  .iti{ 
    width: 100%;
  }

    .voxMail.submitBtn{
      display: flex;
      width:122px;
      height: 36px;
      padding: 0px 8px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-color:transparent;
      border-radius: 64px;
      background: var(--Font-Black-coffee, #3E363F);
      color:white;
      cursor:pointer;
      padding-top:5px;
    }

    .voxMail.submitBtn.newMessage{
      width:203px;
    }
  
    .voxMail.submitBtn:hover{
      background:#262126;
    }

    .voxMail.submitBtn:focus{
      background:#120F12;
    }
    .voxMail.submitBtn:active{
      background:#120F12;
    }

    .voxMail.vmCloseButton:hover path{
      fill:#262126  ;
    }
    .voxMail.vmCloseButton:active path{
      fill:#120F12  ;
    }
  
    .voxMail.submitBtn:disabled{
      background: rgba(130, 130, 133, 0.15);
      color: #828285;
    }

    .voxMail.sendingCircle{
      height:120px;
      width:120px;
      -webkit-animation:spin 4s linear infinite;
      -moz-animation:spin 4s linear infinite;
      animation:spin 4s linear infinite;
    }

    @-moz-keyframes spin { 
      100% { -moz-transform: rotate(360deg); } 
    }

    @-webkit-keyframes spin { 
      100% { -webkit-transform: rotate(360deg); } 
    }

    @keyframes spin { 
      100% { 
          -webkit-transform: rotate(360deg); 
          transform:rotate(360deg); 
      } 
    }

    .voxMailErrorToast {
      visibility: hidden; /* Hidden by default */
      min-width: 250px; /* Set a default minimum width */
      margin-left: -125px; /* Divide value of min-width to center the toast */
      background-color: #333; /* Black background color */
      color: #fff; /* White text color */
      text-align: center; /* Centered text */
      border-radius: 2px; /* Rounded borders */
      padding: 16px; /* Padding */
      position: fixed; /* Sit on top of the screen */
      z-index: 1; /* Add a z-index if needed */
      left: 50%; /* Center the toast */
      bottom: 30px; /* 30px from the bottom */
    }
  
    /* Show the toast when visible is set to true */
    .voxMailErrorToast.show {
      visibility: visible; /* Show the toast */
      -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
      animation: fadein 0.5s, fadeout 0.5s 2.5s;
    }
  
    /* Animations to fade the toast in and out */
    @-webkit-keyframes fadein {
      from {bottom: 0; opacity: 0;} 
      to {bottom: 30px; opacity: 1;}
    }
  
    @keyframes fadein {
      from {bottom: 0; opacity: 0;}
      to {bottom: 30px; opacity: 1;}
    }
  
    @-webkit-keyframes fadeout {
      from {bottom: 30px; opacity: 1;} 
      to {bottom: 0; opacity: 0;}
    }
  
    @keyframes fadeout {
      from {bottom: 30px; opacity: 1;}
      to {bottom: 0; opacity: 0;}
    }

    .voxMail.sendingImage{
      position:absolute;
      display:flex;
      justify-content:center;
      align-items:center;
      left: 30px;
      right: 0;
      top:0;
      bottom:15px;
    }
      
    .voxMail_TogglePicker{
      display: flex;
      padding: 4px;
      justify-content: center;
      align-items: center;
      gap:4px;
      border-radius: 100px;
      border: 1px solid var(--font-black-coffee, #3E363F);
      background: var(--background-white, #FFF);
    }

    .voxMail_TogglePicker.disabled{
      background: rgba(130, 130, 133, 0.30);
      color: #828285;
    }

    .voxMail_ToggleText{
      padding: 6px 18px 4px 18px;
      font-size: 15px;
      font-weight: 600;
      line-height: normal;
      color: var(--font-black-coffee, #3E363F);
      cursor: pointer;
    }

    .voxMail_ToggleText.selected{
      color: white;
      border-radius: 64px;
      border: 1px solid var(--font-black-coffee, #3E363F);
      background: var(--font-black-coffee, #3E363F);
    }

    .voxMail.attachButton{
      position:absolute;
      top:12px;
      right:40px; 
      cursor: pointer;
    }

    .voxMail.attachButtonDiv>input {
      display: none;
    }  

    .voxMail.attachIcon:hover path{
      fill:#D73907  ;
    }

    .voxMail.attachCircle{
      position:absolute;
      top:-10px;
      right:-10px;
      display: inline-flex;
      height: 20px;
      padding: 0px 6.5px;
      justify-content: center;
      align-items: center;
      border-radius: 64px;
      background: #3E363F;
      color: var(--Light-Primary-Contrast, #FFF);
      font-feature-settings: 'clig' off, 'liga' off;
    }

    .voxMail.recordButton{
      height: 56px;
      width: 56px;
      border-radius: 50%;
      background: #D73907;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .voxMail.recordButton:hover{
      background:#B83106;
    }

    .voxMail.recordButton:focus{
      background:#992905;
    }
    .voxMail.recordButton:active{
      background:#992905;
    }

    .voxMail.recordButton.black{
      background:#3E363F;
    }

    .voxMail.recordButton.black:hover{
      background:#262126;
    }

    .voxMail.recordButton.black:focus{
      background:#120F12;
    }
    .voxMail.recordButton.black:active{
      background:#120F12;
    }

    .voxMail.recordingRectangle{
      position: relative;
      width: 100%;
      height: 64px;
      background: #F5F5F7;
      border-radius: 12px;
      border: 1px solid var(--Font-Black-coffee, #3E363F);
      overflow-x:hidden;
      overflow-y:hidden;
      margin-bottom:4px;
    }

    .recHorizontalLine{
      display: block; 
      position: absolute;
      top:0px;
      width:100%;
      height:50%;
      border-bottom: 1px solid var(--Font-Black-coffee, #3E363F);
    }

    .voxMail.canvas{
      background:translucent;
    }

    .voxMail.redLineRecord{
      position: absolute;
      width: 2px;
      background-color: red;
      top: 0;
      left: 0;
      bottom: 0;

      -webkit-animation-name: movingLine;
      -webkit-animation-timing-function: linear;
      -webkit-animation-duration: 30s;
      animation-play-state: paused;
    }

    @keyframes movingLine {
      0% {
        left: 0;
      }

      100% {
        left: 100%;
      }
    }

    .voxMail.redBoxRecord{
      position: absolute;
      background-color: #f007;
      top: 0;
      left: 0;
      bottom: 0;

      -webkit-animation-name: movingBox;
      -webkit-animation-timing-function: linear;
      -webkit-animation-duration: 30s;
      animation-play-state: paused;
    }

    @keyframes movingBox {
      0% {
        right: 100%;
      }

      100% {
        right: 0;
      }
    }

    .voxMail.textArea{
      width:100%;
      height:116px;
      padding: 10px 12px;
      border-radius: 8px;
      background: white;
      resize: none;
      box-sizing:border-box;
      border:none;
      outline:none;
    }

    .voxMail.widgetProgress{
      position:absolute;
      width:100%;
      left: 0;
      right: 0;
      bottom:12px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 4px;
      justify-self: flex-end;
    }
  
    .voxMail.greyCircle{
      height: 6px;
      width: 6px;
      border-radius: 50%;
      background-color: #3E363F;
    }
  
    .voxMail.redCircle{
      height: 6px;
      width: 20px;
      border-radius: 20px;
      background-color: #D73907;
    }

    .voxMail.speechBubble {
      position: fixed; /* Ensure this is relative for absolute positioning inside */
      right: 40px;
      bottom:110px;
      background-color: white;
      color: black;
      display: flex;
      flex-direction: column;
      gap: 8px;
      align-items: flex-start;
      width: 320px;
      height: auto;
      padding: 16px;
      border-radius: 5px;
      box-sizing: border-box;
      margin-right: 0px;
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
      z-index: 1000; /* High z-index to ensure it's above other elements */
    }
    .voxMail.speechBubble.Dark {
      background-color: black;
      color: white;  
    }

    .voxMail.speechBubble.left {
      left:40px;
      right:auto;
    }
    
    .voxMail.dismissBtn {
      /* Add padding inside the button */
      padding: 8px 8px 0px 8px; /* adjust values as needed */
      line-height:80%;
      
      /* Add margin around the button to push away from the text */
      margin-left: 8px; /* adjust value as needed */
      
      /* Optional: Ensure it's placed at the top right corner of the speech bubble */
      position: absolute;
      top: 5px;
      right: 5px;
    
      /* Optional: Style adjustments to make it look more button-like */
      cursor: pointer;
      border-radius: 50%; /* makes it circular, remove if you prefer a square or rectangle */
      color: #666; /* change as needed */
      font-size:30px;

      /* Make sure the button is above all other content */
      z-index: 10;
    }

    .voxMail.dismissBtn.Dark {
      color:white;
      background-color:transparent;
    }
    
    /* Additional style to improve dismiss button accessibility */
    .voxMail.dismissBtn:hover,
    .voxMail.dismissBtn:focus {
      color: #333; /* Darker color on hover/focus */
      background-color: #F4F4F4; /* change as needed */
      text-decoration: none; /* In case you want to remove underline on hover/focus */
    }  

    .voxMail.dismissBtn.Dark:hover,
    .voxMail.dismissBtn.Dark:focus {
      color: #F4F4F8; /* Darker color on hover/focus */
      background-color: transparent; /* change as needed */
    }  


    @media (max-width:600px) {
        .voxMail.imageContainer{
          bottom: 20px; 
          right: 18px;
        }
    }