@import './color.css';
 
 
 .homeScreen{
    flex: 100%;
    padding-bottom: 100px;
    margin-top: -80px;
}


.homeTopSection{
    width: 100%;
    position: relative;
    min-height: 580px;
}


.homeBackground{
    width: 100%;
    display: flex;
    flex-direction: column;
    position: absolute;
    
    top: 0;
    left: 0;
    right: 0;
    

}

.coralBkgHome{
    height: fit-content;
    width: 100%;
    object-fit: contain;
    object-position: 0px -47px;

}

.homeBkgArrow{
    width: 623px;
    margin-left: 276px;
    margin-top: -85px;
}

.homeContainer{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 2000;
    padding-left: 120px;
    padding-right: 120px;
    box-sizing: border-box;
}

.homeContainer.audio{
    align-items: baseline;
}


.homeContent{
    width: fit-content;
    

    margin-top: 142px;
    


    display: flex;
    flex-direction: column;
    align-items: flex-start;

    gap: 88px;
    /* position: absolute; */
    
}

.homeHeader{
    max-width: 541px;
    font-weight: 700;
    font-size: 56px;
    text-align: left;
}

.homeSubcontent{
    display: flex;
    flex-direction: column;
    gap: 28px;
    align-items: flex-start;

    font-weight: 500;
    font-size: 18px;
    text-align: left;
}

.registerBtn{
    width: 142px;
    height: 38px;

    background: var(--BLACK_COFFEE);
    
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
    border-radius: 100px;

    font-weight: 500;
    font-size: 15px;
    color: white;

    cursor: pointer;
}

.homePhone{
    /* position: absolute; */
    width: 261.12px;
    height: 386px;
    /* right: 182px; */
    margin-top: 100px;

}


.homeMiddleSection{
    margin-top: 100px;
    width: 100%;
    height: 199px;
    background-color: var(--BLACK_COFFEE);

    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: 400;
    font-size: 40px;
    color: var(--WHITE);
    text-align: left;

    z-index: 100;

}

.homePlayBtn{
    height: 64px;
    width: 64px;
    border-radius: 50%;
    background-color: var(--TERRA_COTTA);

    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 50px;
    cursor: pointer;
}

.homePlayIcon{
    height: 48px;
    width: 48px;
}

.homeFreq{
    height: 100%;
    width: 485px;
    text-align: left;
}


.homeTilesSection{
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 64px;
    margin-top: 84px;

}


.homeTile{
    width: 333px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;

    font-weight: 700;
    font-size: 24px;
}

.homeTileMain{
    position: relative;
    width: 332.67px;
    height: 240px;
    background: #DADADA;
    border-radius: 20px;
    
}

.homeTileBtn{
    position: absolute;
    bottom: 17px;
    right: 17px;

    height: 40px;
    width: 40px;

    border-radius: 50%;
    background: var(--BLACK_COFFEE);
    box-shadow: 0px 2.94516px 0.98172px -1.96344px rgba(0, 0, 0, 0.2), 0px 1.96344px 1.96344px rgba(0, 0, 0, 0.14), 0px 0.98172px 4.9086px rgba(0, 0, 0, 0.12);

    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.homeTileBtnIcon{
    height: 30px;
    width: 30px;
}

.homeTileSubtitle{
    font-weight: 500;
    font-size: 16px;
    text-align: left;
}



@media (max-width:900px) {

    .homeTopSection{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 40px;
        min-height: fit-content;
        padding: 10px;
        box-sizing: border-box;

    }

    .homePhone{
        position: relative;
        right: 0px;
        top: 0px;
    }

    .homeContent{
        position: relative;
        padding-left: 0px;

    }

    .homeMiddleSection{
        min-height: 600px;
        width: 100%;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        gap: 30px;
        box-sizing: border-box;
        background-color: black;
    }

    

    .homeTilesSection{
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 24px;
        margin-top: 14px;
    }

    .homeFreq{
        width: 100%;
    }


}




/* ---------------------------------------------------------------------------------- */





.homeScreenNew{
    flex: 100%;
    /* padding-bottom: 100px; */
    margin-top: -80px;
    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 80px;
    background-color: var(--SEASALT-GREY);
}


.homeScreenSec1{
    width: 100%;
    position: relative;
    

    padding-top: 175px;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.homeSec1Bkg{
    position: absolute;
    background-color: var(--INDIGO-BLUE);
    height: 720px;
    border-radius: 0 0 50% 50%;
    top: 0px;
    width: 150%;
}


.hss1Container{
    width: 100%;
    padding-left: 120px;
    padding-right: 120px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    max-width: 1366px;
    z-index: 20;
    gap: 20px;

}




.homeScreenSec2{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 100px;
}


.hss2Container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 120px;
    padding-right: 120px;
    box-sizing: border-box;
    max-width: 960px;
    gap: 64px;
}


.hss2EmailImg{
    width: 130%;
    border-radius: 30px;
    box-shadow: 0px 1px 18px 0px rgba(0, 0, 0, 0.12), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 3px 5px -1px rgba(0, 0, 0, 0.20);
    padding:10px;
    align-self: center;
    background-color: white;
    margin-top: 20px;
    /* transform: perspective(200px) rotate3D(0, 0, 0,0.5deg) rotate3D(0, 1, 0,-0.5deg);  */
    

}


.carousel{
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
    gap: 32px;
}

.carouselWindow{
    width: 100%;
    padding: 10px;
}

.paddingLeft688{
    padding-left: 688px;
}


.paddingLeft20{
    padding-left: 20px;
}
.paddingLeft50{
    padding-left: 50px;
}




.carouselBtn{
    z-index: 2000;
    height: 48px;
    width: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 64px;
    background: var(--BG-white, #FFF);

    /* Elevation/6 */
    box-shadow: 0px 1px 18px 0px rgba(0, 0, 0, 0.12), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 3px 5px -1px rgba(0, 0, 0, 0.20);
}

.carouselBtn.left{
    position: absolute;
    left: -24px;
    align-self: center;
}

.carouselBtn.right{
    position: absolute;
    right: -24px;
    align-self: center;
}


.homeScreenSec3{
    width: 100%;
    padding-top: 80px;
    padding-bottom: 120px;
    background-color: white;
    display: flex;
    justify-content: center;
    
}



.hss3Container{
    width: 100%;
    padding-left: 120px;
    padding-right: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 48px;
    box-sizing: border-box;
    max-width: 1366px;;
}

.hss3Container.carousel{
    gap: 20px;
}


.hss3ImageContainer{
    position: relative;
    width: 564px;
    height: 240px;
    border-radius: 20px;
    background-color: var(--BLACK_COFFEE);
    box-shadow: 1px 1px 12px 0px rgba(133, 125, 122, 0.25);
}

.hss3ImageContainer.login{
    width: 100%;
    height: 195px;
    margin-bottom: 20px;
}

.hss3Image{
    /* position: absolute; */
    width: 280px;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
}

.hss3Image.three{
    width: 310px;
}

.homeScreenSec4{
    width: 100%;
    padding-bottom: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 64px;
}

.frequencyPicker{
    width: fit-content;
    display: flex;
    padding: 4px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 100px;
    border: 1px solid var(--font-black-coffee, #3E363F);
    background: var(--background-white, #FFF);
}

.frequency{
    padding: 5px 8px;
    font-size: 14px;
    font-weight: 400;
    color: var(--font-black-coffee, #3E363F);
    cursor: pointer;
}

.frequency.selected{
    font-weight: 500;
    color: white;
    border-radius: 64px;
    border: 1px solid var(--font-black-coffee, #3E363F);
    background: var(--font-black-coffee, #3E363F);
}

.disclaimerTitles{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.disclaimerPopUp{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 24px 24px;
    gap: 20px;

    width: 600px;
    height: auto;

    /* Background/White */

    background: #FFFFFF;
    border-radius: 20px;
}

.disclaimerClose{
    cursor: pointer;
    color: #0000008A;   
}

.homeScreenSec5{
    width: 100%;
    
    background-color: var(--INDIGO-BLUE);
    padding: 64px 120px;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
}

.signUpInput{
    display: flex;
    width: 480px;
    padding: 8px;
    padding-left: 24px;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    border-radius: 100px;
    background: var(--background-white, #FFF);
}


.textInputEmail
{
   width: 270px;
}

.textInputFooter
{
    width: 346px;
    height: 37px;
    border: none;
    color: var(--font-black-coffee, #3E363F);
    font-feature-settings: 'clig' off, 'liga' off;
    /* Subtitle 2 */
    font-family: 'RedHatText';
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
}

.textInputFooter:focus{
    border: none;
    outline: none;
}

.sandboxButton{
    cursor: pointer;
    background-color: transparent;
    border: none;
    margin-top: 40px;
    margin-right: 22px;
}

.dottedBackground{
    position: relative;
    width: 465px;
    height: 639px;
    background-image: url('/public/assets/dottedBkg.png');
    background-size: contain;
    background-repeat: no-repeat;
    padding: 45px;
    box-sizing: border-box;
    margin-right: -65px;
}

.dottedBackground.link{
    margin-top: 50px;
    margin-right: 0px;
}

.widgetContainer{
    height: 549px;
    width: 375px;
}

.widgetContainer.wiggle{

    animation: wiggle 1s infinite;
}

.playbackWidgetContainer{
    height: 91px;
    width: 598px;
}



.testimonyTile{
    display: flex;
    height: 305px;
    width: 264px;
    padding: 20px 24px 24px 24px;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    border-radius: 20px;
    background: #FFF;

    /* shadow */
    box-shadow: 1px 1px 12px 0px rgba(133, 125, 122, 0.25);
}

.testimonialName{
    text-align: left;
    max-width: 96px;
}

.annotationsTT{
    height: 46px;
    color: #340482;
    opacity: 0.5;
    font-family: 'Amita';
    font-size: 120px;
    font-style: normal;
    font-weight: 400;
    font-feature-settings: 'clig' off, 'liga' off;
    line-height: -264%; /* 196.8px */
    letter-spacing: 0.5px;
    
    margin-top: -60px;
    margin-bottom: 60px;
}

.annotationsTT.selected{
    opacity: 1;
}

.voxMailSandbox_playbackWidget{
    width: 100%;
    height: 100%;
}

.voxMailSandbox_timeRowPW{
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 8px;
    font-weight: 500;
  }

  .voxMailSandbox_timeRowCentreTextPW{
    text-align:center;
    padding-right:10px
  }

  .voxMailSandbox_canvasPW{
    background:translucent;
  }

  .voxMailSandbox_recordingRectanglePW{
    position: relative;
    width: 100%;
    height: 100%;
    background: white;
    border: 1px solid var(--BLACK_COFFEE);
    border-radius: 8px;
    overflow: hidden;
  }

  .voxMailSandbox_recordingBtnsRowPW{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 33px;
  }

  .voxMailSandbox_redLineRecordPW{
    position: absolute;
    width: 2px;
    background-color: red;
    top: 0;
    left: 0;
    bottom: 0;

    -webkit-animation-name: movingLine;
    -webkit-animation-timing-function: linear;
    -webkit-animation-duration: 30s;
    animation-play-state: paused;
  }

  @keyframes movingLine {
    0% {
      left: 0;
    }

    100% {
      left: 100%;
    }
  }

  .voxMailSandbox_redBoxRecordPW{
    position: absolute;
    background: linear-gradient(90deg, transparent 50%, #faa 100%);;
    top: 0;
    left: 0;
    bottom: 0;

    -webkit-animation-name: movingBox;
    -webkit-animation-timing-function: linear;
    -webkit-animation-duration: 30s;
    animation-play-state: paused;
  }

  @keyframes movingBox {
    0% {
      right: 100%;
    }

    100% {
      right: 0;
    }
  }



@keyframes wiggle {
    0% { transform: rotate(0deg); }
    10% { transform: rotate(1deg); }
    20% { transform: rotate(-1deg); }
    30% { transform: rotate(2deg); }
    40% { transform: rotate(-2deg); }
    50% { transform: rotate(3deg); }
    60% { transform: rotate(-3deg); }
    70% { transform: rotate(2deg); }
    80% { transform: rotate(-2deg); }
    90% { transform: rotate(1deg); }
    100% { transform: rotate(0deg); }
}

.contactPageHeaders{
    /* align-self: flex-start; */
    margin-right: 30%;
}


@media (max-width:1128px) and (min-width:1024px){
    .sandboxButton{
        visibility: hidden;
    }
}

@media (max-width:1024px) {

    .hss1Container{
        flex-direction: column;
        gap: 35px;
    }

    .dottedBackground{
        margin-right: 0px;
        align-self: center;
    }

    .priceRow{
        flex-wrap: wrap;
        justify-content: center;

    }

   
    

}


@media (max-width:834px) {

    .hss1Container{
        padding-left: 80px;
        padding-right: 80px;
    }

    .hss2Container{
        padding-left: 80px;
        padding-right: 80px;
    }

    .hss3Container{
        padding-left: 80px;
        padding-right: 80px;
    }

    .playbackWidgetContainer{
        width: 550px;
    }


    .priceRow{
        flex-direction: column;
    }

    .contactPageHeaders{
        /* align-self: flex-start; */
        margin-right: auto;
        padding-left: 24px;
        box-sizing: border-box;
    }

}



@media (max-width:600px) {

    .hss1Container{
        padding-left: 24px;
        padding-right: 24px;
    }

    .hss2Container{
        padding-left: 24px;
        padding-right: 24px;
    }

    .hss3Container{
        padding-left: 24px;
        padding-right: 24px;
    }

    .hss3ImageContainer{
        width: 100%;
    }

    .homeSec1Bkg{
        height: 110%;
        width: 240%;
    }


    .dottedBackground.home{
        height: 0px;
        display: none;
        visibility: hidden;
    }

    .playbackRow{
        flex-direction: column-reverse;
        margin-top: -20px;
    }

    .playbackWidgetContainer{
        width: 343px;
    }

    .hss3Image{
        width: 256px;
    }

    .hss3Image.three{
        width: 246px;
    }

    .homeScreenSec4{
        padding-left: 24px;
        padding-right: 24px;
        box-sizing: border-box;
    }


    

    


}




.aboutUsScreen{
    flex: 100%;
    padding-bottom: 100px;
    margin-top: 0px;
    background-color: #340482;
    padding-top: 20px;
}

.aboutUsImg{
    background-color: red;
    height: 250px;
    width: 225px;
    object-fit: cover;
    border-radius: 12px;
}


